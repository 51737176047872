<script>
/**
 * 头部组件-用户头像与昵称，退出登录
 * @Author: lili
 * @Date: 2022-11-16 14：47
 *
 */
import { changePassword } from "@/apis/core"

export default {
  name: "HeaderCom",
  data () {
    return {
      // 修改密码弹窗
      dialog: false,
      ruleForm: {
        pwd: '',
        newPwd: '',
        confirmPwd: ''
      },
      rules: {
        pwd: [
          {required: true, message: "请输入旧密码", trigger: "blur"},
          {validator: this.validStr, trigger: 'blur'}
        ],
        newPwd: [
          {required: true, message: "请输入新密码", trigger: "blur"},
          {validator: this.validStr, trigger: 'blur'},
          { validator: this.validateNewPassword, trigger: 'blur' }
        ],
        confirmPwd: [
          {required: true, message: "请输入确认密码", trigger: "blur"},
          {validator: this.validStr, trigger: 'blur'},
          { validator: this.validatePassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onHandleCommandClick(path) {
      if (path === 'logOut') {
        this.$store.dispatch("useUserStore/logOut").then(() => {
          this.$router.replace({name: "login"})
        })
        return;
      } else if (path === "resetPwd") {
        // 修改密码
        this.dialog = true
      }
    },
    validatePassword(rule, value, callback) {
      if (value !== this.ruleForm.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    validateNewPassword(rule, value, callback) {
      if (value === this.ruleForm.pwd) {
        callback(new Error('新密码与旧密码不能相同!'));
      } else {
        callback();
      }
    },
    validStr(rule, value, callback) {
      const regex = /^[a-zA-Z0-9]*$/
      if (!regex.test(value)) {
        callback(new Error('只能输入字母或数字!'));
      } else {
        callback()
      }
    },
    /**
     * 取消修改密码
     */
    cancelResetPwd() {
      this.dialog = false
      this.ruleForm = {
        pwd: '',
        newPwd: '',
        confirmPwd: ''
      }
    },
    /**
     * 确认修改密码
     */
    confirmResetPwd() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          changePassword(this.ruleForm.pwd, this.ruleForm.newPwd).then(e => {
            if (e) {
              this.$store.dispatch("useUserStore/logOut").then(() => {
                this.$router.replace({name: "login"})
              })
            }
          })
        }
      })
    }
  }
}
</script>

<template>
  <el-header class="header" height="60px">
    <div class="layout-navbars-breadcrumb-user">
      <img class="photo" src="../../../assets/image/user.png"/>
      <template v-if="$store.getters.token">
      <el-dropdown :show-timeout="70" :hide-timeout="50" @command="onHandleCommandClick">
                <span class="layout-navbars-breadcrumb-user-link">
                  {{$store.getters.userInfo?$store.getters.userInfo.realName:'' || ""}}
                 <i class="el-icon-setting" style="margin:0 15px"></i>
                </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="resetPwd">修改密码</el-dropdown-item>
            <el-dropdown-item divided command="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      </template>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialog"
      width="600px"
      :destroy-on-close="true"
      center
    >
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="旧密码" prop="pwd">
          <el-input type="password" :maxlength="20" v-model="ruleForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input type="password" :maxlength="20" v-model="ruleForm.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input type="password" :maxlength="20" v-model="ruleForm.confirmPwd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelResetPwd">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmResetPwd">确 定</el-button>
      </span>
    </el-dialog>
  </el-header>
</template>

<style scoped lang="scss">
.header {
  line-height: 60px;
  text-align: right;
  display: flex;
  justify-content:flex-end;
  background: #FFFFFF !important;
  box-shadow: 1px 1px 5px #e5e0e0;
  margin: 24px;
  margin-bottom: 0;
}

.layout-navbars-breadcrumb-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;


  }

  .photo{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin: 0 10px;
  }
  :deep(.el-dropdown) {
    color: var(--bg-topBarColor);
  }

  :deep(.el-badge) {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }

  :deep(.el-badge__content.is-fixed) {
    top: 12px;
  }
}
:deep(.el-dialog__header) {
  text-align: center;
  line-height: 24px;
}
</style>
